import { FC, useEffect, useState } from "react";

import { ReactComponent as InfoIcon } from "@src/assets/images/icons/info.svg";
import { Button, ManIcons, MobileModal, Picture } from "@src/components";
import { useActions, useAppSelector, useGetBaseData } from "@src/hooks";
import { IRate, Rooms } from "@src/interfaces";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";

type RatesMobileProps = {
  room: Rooms;
  onSetRateCounter: (rate: IRate, id: string, price: string, isCancel: boolean) => void;
  rateCounter: { [key: string]: number };
  isAllSelected: boolean;
  allRates: number;
  allGuests: number;
  totalPrice: number;
  numberOfNights: number;
  totalRoomAvailable?: string;
  roomId: number;
  handleRatesModalClose: () => void;
  setOpenYourChoice: () => void;
  setOpenRates?: (isOpen: boolean) => void;
};

export const RatesDekstop: FC<RatesMobileProps> = ({
  allRates,
  onSetRateCounter,
  rateCounter,
  isAllSelected,
  room,
  numberOfNights,
  totalRoomAvailable,
  roomId,
  handleRatesModalClose,
  setOpenYourChoice,
  setOpenRates,
}) => {
  const { img, name, rates, total_room_available } = room;
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false);
  const [isOpenAlreadyChoose, setIsOpenAlreadyChoose] = useState(false);
  const {
    chooseButtonText,
    chooseReserve,
    cancelButton,
    bookingCancelWarning,
    alreadyChooseText,
    textNight,
    currencyValue,
    roomText,
    roomsText,
    roomsTextGenetiv,
    textNights,
    textNightsGenetiv,
    textSuggestionFor,
  } = useGetBaseData();
  const alreadyChoose = alreadyChooseText.replace("#rooms_count#", String(allRates));
  const { tariffCounter, roomCounter, selectedRooms } = useAppSelector((state) => state.bookingData);
  const { setTariffCounter, setRoomCounter } = useActions();
  const allGuestsHaveSelectedRates = selectedRooms.length === allRates;

  const ROOMS_DECL = [roomText, roomsText, roomsTextGenetiv];
  const NIGHTS_DECL = [textNight, textNights, textNightsGenetiv];

  const nightsText = `${textSuggestionFor} ${numberOfNights} ${declOfNum(numberOfNights, NIGHTS_DECL)}`;

  const [isMaxAmountOfRoomsChosen, setIsMaxAmountOfRoomsChosen] = useState(selectedRooms.length === allRates);

  useEffect(() => {
    setIsMaxAmountOfRoomsChosen(selectedRooms.length === allRates);
  }, [selectedRooms.length, allRates]);

  useEffect(() => {
    if (isMaxAmountOfRoomsChosen) {
      handleRatesModalClose();
      setOpenYourChoice();
    }
  }, [isMaxAmountOfRoomsChosen]);

  const handleAddCurrentRoom = () => {
    if (roomCounter[roomId]) {
      setRoomCounter({ ...roomCounter, [roomId]: roomCounter[roomId] + 1 });
    } else {
      setRoomCounter({ ...roomCounter, [roomId]: 1 });
    }
  };

  const resetNumberOfCurrentRoom = () => {
    setRoomCounter({ ...roomCounter, [roomId]: 0 });
  };

  const handleAddTariffRoom = (id: string) => {
    if (tariffCounter[id]) {
      setTariffCounter({ ...tariffCounter, [id]: tariffCounter[id] + 1 });
    } else {
      setTariffCounter({ ...tariffCounter, [id]: 1 });
    }
  };

  const resetNumberOfTariffRoom = (id: string) => {
    setTariffCounter({ ...tariffCounter, [id]: 0 });
  };

  const areRoomsOfTariffAvailable = (id: string, maxValue?: string) => {
    if (tariffCounter[id] && maxValue) {
      return tariffCounter[id] < Number(maxValue);
    } else {
      return true;
    }
  };

  const isRoomAvailable = () => {
    if (roomCounter[roomId] && totalRoomAvailable) {
      return roomCounter[roomId] < Number(totalRoomAvailable);
    } else {
      return true;
    }
  };

  useEffect(() => {
    const updatedAllGuestsHaveSelectedRates = selectedRooms.length === allRates;
    if (updatedAllGuestsHaveSelectedRates && setOpenRates) {
      setOpenRates(false);
    }
  }, [selectedRooms, allRates]);

  return (
    <>
      {isOpenMobileModal && (
        <MobileModal onClose={() => setIsOpenMobileModal(false)}>
          <Styled.CancelWarning>{bookingCancelWarning}</Styled.CancelWarning>
        </MobileModal>
      )}
      {isOpenAlreadyChoose && (
        <MobileModal onClose={() => setIsOpenAlreadyChoose(false)}>
          <Styled.CancelWarning>{alreadyChoose}</Styled.CancelWarning>
        </MobileModal>
      )}
      <Styled.RatesMobileWrapper>
        {rates?.map((rate, index) => (
          <Styled.RateWrapper key={rate.id}>
            <Styled.RateName>{rate.name}</Styled.RateName>
            <Styled.RateDescription>
              {rate.cancel_text || "Бесплатная отмена бронирования"}
              <InfoIcon onClick={() => setIsOpenMobileModal(true)} />
            </Styled.RateDescription>
            <Styled.RateDescription>{rate.payment_text || "Оплата банковской картой"}</Styled.RateDescription>
            {rate.prices.map((p) => {
              const formatedPrice = String(p.total)
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                .split(".")[0];
              const showAddBtn =
                !isAllSelected &&
                isRoomAvailable() &&
                areRoomsOfTariffAvailable(rate.id, rate.this_type_room_available);
              const showdisabledBtn =
                (isAllSelected ||
                  !isRoomAvailable() ||
                  !areRoomsOfTariffAvailable(rate.id, rate.this_type_room_available)) &&
                !rateCounter[p.id];

              const showCancelBtn =
                (isAllSelected ||
                  !isRoomAvailable() ||
                  !areRoomsOfTariffAvailable(rate.id, rate.this_type_room_available)) &&
                !!rateCounter[p.id];

              return (
                <Styled.RateFooter key={p.id}>
                  <Styled.PriceWrapper>
                    <Styled.Price isSecond={Number(rate.loyalty) === 0}>
                      {formatedPrice} {currencyValue}
                      <Styled.PerNight>{nightsText}</Styled.PerNight>
                    </Styled.Price>
                    <ManIcons price={p} color={Number(rate.loyalty) === 0 ? "black" : "#A0072B"} />
                  </Styled.PriceWrapper>
                  {showAddBtn && (
                    <Button
                      onClick={() => {
                        handleAddCurrentRoom();
                        handleAddTariffRoom(rate.id);
                        isAllSelected && !rateCounter[p.id]
                          ? setIsOpenAlreadyChoose(true)
                          : onSetRateCounter(
                              rate,
                              p.id,
                              p.total,
                              (isAllSelected || !isRoomAvailable() || !areRoomsOfTariffAvailable(rate.id)) &&
                                !!rateCounter[p.id]
                            );

                        {
                          if (allGuestsHaveSelectedRates && setOpenRates) {
                            setOpenRates(false);
                          }
                        }
                      }}
                      unDisabled
                      counter={rateCounter[p.id]}
                      title={chooseReserve}
                      primary={!isAllSelected}
                    />
                  )}
                  {showdisabledBtn && (
                    <Button
                      onClick={() =>
                        isAllSelected && !rateCounter[p.id]
                          ? setIsOpenAlreadyChoose(true)
                          : onSetRateCounter(rate, p.id, p.total, isAllSelected && !!rateCounter[p.id])
                      }
                      unDisabled
                      isDisabled
                      counter={rateCounter[p.id]}
                      title={chooseReserve}
                    />
                  )}

                  {showCancelBtn && (
                    <Button
                      onClick={() => {
                        resetNumberOfCurrentRoom();
                        resetNumberOfTariffRoom(rate.id);

                        isAllSelected && !rateCounter[p.id]
                          ? setIsOpenAlreadyChoose(true)
                          : onSetRateCounter(
                              rate,
                              p.id,
                              p.total,
                              (isAllSelected ||
                                !isRoomAvailable() ||
                                !areRoomsOfTariffAvailable(rate.id, rate.this_type_room_available)) &&
                                !!rateCounter[p.id]
                            );
                      }}
                      unDisabled
                      width={110}
                      counter={rateCounter[p.id]}
                      title={cancelButton}
                      outline={isAllSelected && !!rateCounter[p.id]}
                    />
                  )}
                </Styled.RateFooter>
              );
            })}
          </Styled.RateWrapper>
        ))}
      </Styled.RatesMobileWrapper>
    </>
  );
};
