import { format } from "date-fns";
import { memo, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "@src/assets/images/icons/arrow.svg";
import { ReactComponent as Loader } from "@src/assets/svg/loader.svg";
import { Button, MobilePopup } from "@src/components";
import { Booked } from "@src/components/ChooseRoom/Booked";
import { BookedMobile } from "@src/components/ChooseRoom/BookedMobile";
import ChooseRoomsCard from "@src/components/ChooseRoom/ChooseRoomsCard/ChooseRoomsCard";
import { useGetOurRoomsData } from "@src/components/OurRoomsSection/useGetOurRoomsData";
import { FROM_TO_DATE_FORMAT, RESERVATION_ROUTES } from "@src/constants";
import { useActions, useAppSelector, useGetBaseData } from "@src/hooks";
import { IRate } from "@src/interfaces";
import { useGetBookingSearchDataQuery } from "@src/store/services";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";
import {
  deleteDuplicateObjectsFromArray,
  getRatesToChooseWithAmountInfo,
  getSelectedRatesIDs,
  handleGetUpdatedRatesOfSelectedRooms,
  updateAmountOfRatesToChoose,
} from "./utils";

export type GuestInRateType = {
  adult_count: number;
  child_under_11_count: number;
  child_under_5_count: number;
  rate_string?: string;
  rate_id?: string;
};

type IdType = { id: string };
export type RatesToBlockType = GuestInRateType & IdType;

export type RoomWithCountType = {
  id: string;
  count: number;
  adult_count: number;
  child_under_11_count: number;
  child_under_5_count: number;
};

export const ChooseRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const { selectRoom } = useGetOurRoomsData();
  const {
    proposalS,
    pretextFor,
    textNight,
    textNights,
    textNightsGenetiv,
    currencyValue,
    roomText,
    roomsText,
    roomsTextGenetiv,
    guestText,
    guestsText,
    guestsAddText,
    roomIsUnavailableText,
    searchRoomError,
  } = useGetBaseData();

  const { guestsData, selectedRooms, amountRates, rateCounter } = useAppSelector((state) => state.bookingData);
  const {
    setBookedFormData,
    setSelectedRooms,
    setAmountRates,
    setRateCounter,
    toggleSpace,
    setTariffCounter,
    setRoomCounter,
  } = useActions();
  const { arrivalDateValue, departureDateValue, guestsNumber, roomsNumber } = useAppSelector(getDates);
  const [openYourChoice, setOpenYourChoice] = useState(false);
  const { data = [], isLoading, error, isSuccess } = useGetBookingSearchDataQuery(guestsData);

  const noRoomsFound = isSuccess && !data;

  const allRates = guestsData.rooms?.length;
  const allGuests = guestsData.rooms.flatMap((room) => Object.values(room)).reduce((acc, curr) => acc + curr);

  if (!guestsData.date_from) {
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`);

    return null;
  }
  const bookingDate = `${proposalS} ${format(
    new Date(guestsData.date_from),
    FROM_TO_DATE_FORMAT
  )} ${pretextFor} ${format(new Date(guestsData.date_to), FROM_TO_DATE_FORMAT)}`;

  const timeDiff = Math.abs(new Date(departureDateValue).getTime() - new Date(arrivalDateValue).getTime());
  const numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const nightsText = `${numberOfNights} ${declOfNum(numberOfNights, [
    textNight,
    textNights,
    textNightsGenetiv,
  ])} ${bookingDate}`;

  const roomsTextValue = `${roomsNumber} ${declOfNum(Number(roomsNumber), [roomText, roomsText, roomsTextGenetiv])}`;

  const guestsTextValue = `${guestsNumber} ${declOfNum(Number(guestsNumber), [guestText, guestsText, guestsAddText])}`;

  const roomsGuestText = `${roomsTextValue}, ${guestsTextValue}`;

  useEffect(() => {
    setSelectedRooms([]);
    setAmountRates(allRates);
    setRateCounter({});
    setTariffCounter({});
    setRoomCounter({});
  }, []);

  const isAllSelected = amountRates === 0;

  useEffect(() => {
    toggleSpace(isAllSelected);
  }, [isAllSelected]);

  const totalPrice = selectedRooms.reduce((acc, curr) => acc + +curr.price, 0);

  const handleSetRateCounter = (rate: IRate, id: string, price: string, isCancel: boolean) => {
    if (isAllSelected && !isCancel) return;
    setSelectedRooms(
      isCancel
        ? selectedRooms.filter((room) => room.priceId !== id)
        : [...selectedRooms, { ...rate, priceId: id, price }]
    );
    setAmountRates(isCancel ? amountRates + rateCounter[id] : amountRates - 1);

    if (rateCounter[id]) {
      setRateCounter({ ...rateCounter, [id]: isCancel ? 0 : rateCounter[id] + 1 });
    } else {
      setRateCounter({ ...rateCounter, [id]: 1 });
    }
  };

  const handleDeleteRoomClick = (priceId: string, priceIndex: number) => {
    setSelectedRooms(selectedRooms.filter((room, index) => index !== priceIndex));
    setAmountRates(amountRates + 1);
    setRateCounter({ ...rateCounter, [priceId]: rateCounter[priceId] - 1 });
  };

  const handleMobileDeleteRoomClick = (priceId: string, priceIndex: number) => {
    setSelectedRooms(selectedRooms.filter((_, index) => index !== priceIndex));
    setAmountRates(amountRates + 1);
    setRateCounter({ ...rateCounter, [priceId]: rateCounter[priceId] - 1 });
  };

  const handleDeleteSelectedRateClick = (name: string) => {
    const filteredRooms = selectedRooms.filter((room, index) => room.room_name !== name);
    setSelectedRooms(filteredRooms);
    setAmountRates(allRates - filteredRooms.length);
    const newCounter = filteredRooms.reduce((acc: any, obj) => {
      if (acc[obj.priceId]) {
        return { ...acc, [obj.priceId]: (acc[obj.priceId] += 1) };
      } else {
        return { ...acc, [obj.priceId]: (acc[obj.priceId] = 1) };
      }
    }, {});

    setRateCounter(newCounter);
  };

  const handleToReservationPageClick = () => {
    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_GUESTS_COUNTER}`);
  };

  const finishBookingData = {
    model: {
      date_from: guestsData.date_from,
      date_to: guestsData.date_to,
      rooms: guestsData.rooms.map((item, index: number) => ({
        ...item,
        room_code: selectedRooms[index]?.room_code,
        rate_code: selectedRooms[index]?.rate_code,
      })),
    },
    rooms: [...selectedRooms],
  };

  const handleNavigationClick = () => {
    setBookedFormData(finishBookingData);

    !isEnglishVersion
      ? navigate(`${RESERVATION_ROUTES.RESERVATION_SELECT_PAY}`)
      : navigate(`/en${RESERVATION_ROUTES.RESERVATION_SELECT_PAY}`);
  };

  // БЛОКИРОВКА ТАРИФОВ

  const [ratesToChooseWithAmount, setRatesToChooseWithAmount] = useState<RoomWithCountType[]>([]);
  useEffect(() => {
    const updatedRoomsWithCount = getRatesToChooseWithAmountInfo(guestsData);
    setRatesToChooseWithAmount(updatedRoomsWithCount);
  }, [guestsData.rooms]);

  const [selectedRatesIDs, setSelectedRatesIDs] = useState<string[]>([]);
  useEffect(() => {
    const selectedRatesIDsArray = getSelectedRatesIDs(rateCounter);
    setSelectedRatesIDs(selectedRatesIDsArray);
  }, [rateCounter]);

  const [allRatesOfSelectedRooms, setAllRatesOfSelectedRooms] = useState<GuestInRateType[]>([]);
  useEffect(() => {
    const updatedRatesOfSelectedRooms = handleGetUpdatedRatesOfSelectedRooms(selectedRooms, selectedRatesIDs);
    setAllRatesOfSelectedRooms((prev) => (prev = updatedRatesOfSelectedRooms));
  }, [selectedRooms, selectedRatesIDs]);

  const [uniqueGuestsDataRoomsWithAmountToChange, setUniqueGuestsDataRoomsWithAmountToChange] = useState<
    RoomWithCountType[]
  >([]);

  useEffect(() => {
    const updatedChosenRoomsWithCounter = updateAmountOfRatesToChoose(ratesToChooseWithAmount, allRatesOfSelectedRooms);
    setUniqueGuestsDataRoomsWithAmountToChange((prev) => (prev = updatedChosenRoomsWithCounter));
  }, [allRatesOfSelectedRooms]);

  const [ratesToBlock, setRatesToBlock] = useState<RatesToBlockType[]>([]);
  useEffect(() => {
    uniqueGuestsDataRoomsWithAmountToChange.forEach((item) => {
      const isRateItemAlreadyBlocked = ratesToBlock.some((rateItem) => rateItem.id === item.id);
      if (item.count < 1) {
        setRatesToBlock((prevArray) => {
          const newRatesToBlockArr = [
            ...prevArray,
            {
              id: item.id,
              adult_count: item.adult_count,
              child_under_11_count: item.child_under_11_count,
              child_under_5_count: item.child_under_5_count,
            },
          ];

          const newRatesToBlockUniqueArr = deleteDuplicateObjectsFromArray(newRatesToBlockArr, "id");
          return newRatesToBlockUniqueArr;
        });
      } else if (item.count >= 1 && isRateItemAlreadyBlocked) {
        const updatedRatesToBlock = ratesToBlock.filter((stateItem) => stateItem.id !== item.id);
        setRatesToBlock(updatedRatesToBlock);
      }
    });
  }, [uniqueGuestsDataRoomsWithAmountToChange]);

  return (
    <>
      <Styled.ChooseRoomsHeader>
        <Button
          background="none"
          mr="20px"
          onClick={handleToReservationPageClick}
          icon={<ArrowIcon stroke="black" />}
          iconAction
        />
        <Styled.BookingDate>
          <span>{bookingDate}</span>
          {roomsGuestText}
        </Styled.BookingDate>
      </Styled.ChooseRoomsHeader>
      <Styled.Title>{selectRoom}</Styled.Title>
      <Styled.ChooseRoomWrapper>
        {openYourChoice && selectedRooms.length >= 1 && (
          <MobilePopup onClose={() => setOpenYourChoice(false)}>
            {selectedRooms.map((room, index) => {
              const formatedPrice = String(room.price)
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                .split(".")[0];
              return (
                <div key={index}>
                  {room.id == "" ? (
                    <></>
                  ) : (
                    <Styled.LastRate>
                      <Styled.Checked />
                      <Styled.RateDescription>
                        <div>{room.room_name}</div>
                        <Styled.RateName>{room.name}</Styled.RateName>
                        {formatedPrice} {currencyValue}
                      </Styled.RateDescription>
                    </Styled.LastRate>
                  )}
                </div>
              );
            })}
          </MobilePopup>
        )}
        <Styled.ChooseRooms>
          {isLoading && !!data ? (
            <Styled.Loader>
              <Loader />
            </Styled.Loader>
          ) : error ? (
            <Styled.NoRoomsText>{searchRoomError}</Styled.NoRoomsText>
          ) : noRoomsFound ? (
            <Styled.NoRoomsText>{roomIsUnavailableText}</Styled.NoRoomsText>
          ) : (
            data?.length &&
            data.map((room, index) => (
              <ChooseRoomsCard
                allGuests={allGuests}
                totalPrice={totalPrice}
                allRates={allRates}
                setOpenYourChoice={() => setOpenYourChoice(true)}
                onDeleteSelectedRateClick={handleDeleteSelectedRateClick}
                isAllSelected={isAllSelected}
                selectedRooms={selectedRooms}
                allRooms={data}
                bookingDate={bookingDate}
                room={room}
                key={`${room.id}/${index}`}
                onSetRateCounter={handleSetRateCounter}
                rateCounter={rateCounter}
                onDeleteRoomClick={handleDeleteRoomClick}
                onMobileDeleteRoomClick={handleMobileDeleteRoomClick}
                ratesToBlock={ratesToBlock}
                roomId={room.id}
              />
            ))
          )}

          {isAllSelected && <BookedMobile totalPrice={totalPrice} allRates={allRates} allGuests={allGuests} />}
        </Styled.ChooseRooms>
        <Styled.Booked>
          <Booked
            nightsText={nightsText}
            onDeleteRoomClick={handleDeleteRoomClick}
            onNavigationClick={handleNavigationClick}
            bookedData={selectedRooms}
            roomsAmount={amountRates}
            totalPrice={totalPrice}
            isDisabled={!isAllSelected}
            allRates={allRates}
          />
        </Styled.Booked>
      </Styled.ChooseRoomWrapper>
    </>
  );
};
