import { FC } from "react";

import { ReactComponent as CheckIcon } from "@src/assets/images/icons/check.svg";
import { ReactComponent as DeleteIcon } from "@src/assets/images/icons/trash-icon.svg";
import { useActions, useAppSelector } from "@src/hooks";

import { Styled } from "./styles";

type MobileSelectedTariffProps = {
  title: string;
  priceId: string;
  index: number;
  roomId: number;
  currentRoom: any;
  room: any;
  onMobileDeleteRoomClick?: (priceId: string, priceIndex: number) => void;
  isSelected?: boolean;
};
export const SelectedTariff: FC<MobileSelectedTariffProps> = ({
  title,
  priceId,
  room,
  index,
  onMobileDeleteRoomClick,
  roomId,
  currentRoom,
}) => {
  const { tariffCounter, roomCounter } = useAppSelector((state) => state.bookingData);
  const { setTariffCounter, setRoomCounter } = useActions();
  const currentTariffId = currentRoom[0].id;
  const resetNumberOfTariffRoom = (id: string) => {
    setTariffCounter({ ...tariffCounter, [id]: 0 });
  };
  const resetNumberOfCurrentRoom = () => {
    setRoomCounter({ ...roomCounter, [roomId]: 0 });
  };
  const handleDeleteClick = () => {
    resetNumberOfTariffRoom(currentTariffId);
    resetNumberOfCurrentRoom();
    onMobileDeleteRoomClick?.(priceId, index);
  };

  return (
    <Styled.SelectedRoom>
      <Styled.CheckIconContainer>
        <CheckIcon />
      </Styled.CheckIconContainer>

      <Styled.Text>{title}</Styled.Text>

      <Styled.TrashIconContainer onClick={handleDeleteClick}>
        <DeleteIcon />
      </Styled.TrashIconContainer>
    </Styled.SelectedRoom>
  );
};
