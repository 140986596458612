import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { ChangeEvent, FC, forwardRef, MouseEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import CalendarIcon from "@src/assets/images/icons/calendar.svg";
import { Button, FormInput, Select } from "@src/components/_Ui";
import { DateCalendar } from "@src/components/_Ui/Calendar";
import FormSelect from "@src/components/_Ui/FormSelect/FormSelect";
import { FormTextarea } from "@src/components/_Ui/FormTextarea";
import { useActions, useGetBaseData, useMediaQuery } from "@src/hooks";
import { RoomsOptionType } from "@src/interfaces/event.interfaces";
import { useGetEventsQuery } from "@src/store/services";
import { theme } from "@src/theme";

import { Styled } from "./styles";

type RequestEventType = {
  lastName: string;
  name: string;
  phone: string;
  email: string;
  members: string;
  date: Date | null;
  room: string;
  comment: string;
};

export const EventRequestForm = forwardRef<HTMLDivElement, Record<string, unknown>>((props, ref) => {
  const {
    validationLastname,
    validationName,
    validationEmail,
    validationPhone,
    eventsFormTitleText,
    eventsFormLastnameText,
    eventsFormNameText,
    eventsFormPhoneText,
    eventsFormEmailText,
    eventsFormMembersText,
    eventsFormDateText,
    eventsFormRoomText,
    eventsFormCommentText,
    eventsFormErrorText,
    eventIncorrectPhoneError,
    eventParticipantsError,
    eventDateError,
    eventIncorrectEmailError,
    eventSendFormBtnText,
    bookingWrongFormatError,
  } = useGetBaseData();
  const { requestConferenceRoom } = useActions();
  const { data } = useGetEventsQuery();

  const [hallNames, setHallNames] = useState<RoomsOptionType[]>([]);
  useEffect(() => {
    if (data?.eventHalls) {
      const hallNamesData = data?.eventHalls?.map((hall) => {
        return { value: hall.text, label: hall.text };
      });
      setHallNames(hallNamesData);
    }
  }, [data]);

  // phone validation
  const [phoneLengthToValidate, setPhoneLengthToValidate] = useState(11);

  const validationSchema = yup.object({
    lastName: yup
      .string()
      .required(validationLastname)
      .matches(/^[A-Za-zа-яА-Я\s-]+$/, bookingWrongFormatError),
    name: yup
      .string()
      .required(validationName)
      .matches(/^[A-Za-zа-яА-Я\s-]+$/, bookingWrongFormatError),
    phone: yup.string().required(validationPhone).min(phoneLengthToValidate, eventIncorrectPhoneError),
    email: yup.string().email(eventIncorrectEmailError).required(validationEmail),
    members: yup.string().required(eventParticipantsError),
    date: yup.string().nullable().required(eventDateError),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
    resetField,
  } = useForm<RequestEventType>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const currentPhoneValue = watch?.("phone");

  useEffect(() => {
    const isRussianNumber = currentPhoneValue?.[0] === "8" || currentPhoneValue?.[1] === "7";
    const RUSSIAN_PHONE_LENGTH = 18;
    const FOREIGN_COUNTRY_PHONE_LENGTH = 12;
    const lengthToValidate = isRussianNumber ? RUSSIAN_PHONE_LENGTH : FOREIGN_COUNTRY_PHONE_LENGTH;
    setPhoneLengthToValidate((prev) => (prev = lengthToValidate));
  }, [currentPhoneValue]);

  const chosenDate = watch("date");

  const isAdaptive = useMediaQuery(theme.breakpoints.large);

  const [errorsLength, setErrorsLength] = useState<number>(0);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setErrorsLength(Object.keys(errors).length);
  }, [errors]);

  function formatDate(inputDate: string) {
    const parts = inputDate.split(".");
    const days = parts[0];
    const months = parts[1];
    const years = parts[2];

    const formattedDate = `${years}-${months}-${days}`;

    return formattedDate;
  }

  const handleNumericInput = (evt: ChangeEvent<HTMLInputElement>) => {
    const numericValue = evt.target.value.replace(/\D/g, "");

    if (numericValue !== evt.target.value) {
      evt.preventDefault();
    }

    setValue("members", numericValue, { shouldValidate: true });
  };

  const handleFormSubmit: SubmitHandler<RequestEventType> = async (data) => {
    const formattedDate = formatDate(data.date as unknown as string);

    const dataToSend = {
      ...data,
      date: format(new Date(formattedDate), "yyyy-MM-dd"),
    };

    requestConferenceRoom(dataToSend);

    reset({
      lastName: "",
      name: "",
      phone: "",
      email: "",
      members: "",
      date: null,
      room: "",
      comment: "",
    });
  };

  const handleDateInputClick = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = async () => {
    setModalIsOpen(false);
  };

  const textAreaHeight = isAdaptive ? 187 : 0;

  const ErrorText: FC<{ errors: number }> = ({ errors }) => {
    const errorHighlightedText = eventsFormErrorText
      .replace("#FIELDS#", String(errors))
      .split(" ")
      .slice(0, 2)
      .join(" ");
    const errorText = eventsFormErrorText.split(" ").slice(2).join(" ");
    return (
      <Styled.ErrorsContainer>
        <Styled.ErrorText>
          <Styled.ErrorTextValue>{errorHighlightedText}</Styled.ErrorTextValue>
          {errorText}
        </Styled.ErrorText>
      </Styled.ErrorsContainer>
    );
  };

  return (
    <>
      <Styled.FormContainer ref={ref}>
        <Styled.Title>{eventsFormTitleText}</Styled.Title>
        <Styled.Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.InputsContainer>
            <Styled.InputContainer hasError={Boolean(errors.lastName)}>
              <FormInput
                {...register("lastName")}
                hasValue={!!getValues(`lastName`)}
                placeholder={eventsFormLastnameText}
                error={errors.lastName}
                required
                maxWidth
                inputName="lastName"
                resetField={resetField}
              />
            </Styled.InputContainer>
            <Styled.InputContainer hasError={Boolean(errors.name)}>
              <FormInput
                {...register("name")}
                hasValue={!!getValues(`name`)}
                placeholder={eventsFormNameText}
                error={errors.name}
                required
                maxWidth
                inputName="name"
                resetField={resetField}
              />
            </Styled.InputContainer>
            <Styled.InputContainer hasError={Boolean(errors.phone)}>
              <FormInput
                {...register("phone")}
                placeholder={eventsFormPhoneText}
                isPhone
                hasFlag
                hasValue={!!getValues(`phone`)}
                error={errors.phone}
                required
                maxWidth
                inputName="phone"
                resetField={resetField}
              />
            </Styled.InputContainer>

            <Styled.InputContainer hasError={Boolean(errors.email)}>
              <FormInput
                {...register("email")}
                placeholder={eventsFormEmailText}
                hasValue={!!getValues(`email`)}
                error={errors.email}
                required
                maxWidth
                inputName="email"
                resetField={resetField}
              />
            </Styled.InputContainer>
            <Styled.InputContainer hasError={Boolean(errors.members)}>
              <FormInput
                {...register("members")}
                placeholder={eventsFormMembersText}
                error={errors.members}
                hasValue={!!getValues(`members`)}
                required
                type="text"
                maxWidth
                inputName="members"
                resetField={resetField}
                onInput={handleNumericInput}
              />
            </Styled.InputContainer>

            <Styled.InputContainer hasError={Boolean(errors.date)}>
              <FormInput
                {...register("date")}
                placeholder={eventsFormDateText}
                error={errors.date}
                hasValue={!!getValues("date")}
                required
                maxWidth
                onDateClick={handleDateInputClick}
                icon={CalendarIcon}
                inputName="date"
                resetField={resetField}
                dateModalIsOpen={modalIsOpen}
              />
            </Styled.InputContainer>
          </Styled.InputsContainer>

          <Styled.RoomContainer>
            <Controller
              name="room"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormSelect
                  error={error}
                  field={field}
                  placeholder={eventsFormRoomText}
                  options={hallNames}
                  inputName="room"
                  resetField={resetField}
                />
              )}
            />
          </Styled.RoomContainer>

          <Styled.CommentContainer>
            <Controller
              name={"comment"}
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormTextarea
                  placeholder={eventsFormCommentText}
                  rows={4}
                  maxRows={5}
                  value={value}
                  hasValue={!!getValues(`comment`)}
                  onChange={onChange}
                  minHeight={textAreaHeight}
                />
              )}
            />
          </Styled.CommentContainer>

          <Button height="64px" mt="32px" title={eventSendFormBtnText} primary type="submit" />

          {errorsLength > 0 && <ErrorText errors={errorsLength} />}
        </Styled.Form>
      </Styled.FormContainer>
      {modalIsOpen && (
        <DateCalendar isOpen={modalIsOpen} closeModal={handleModalClose} setValue={setValue} register={register} />
      )}
    </>
  );
});

EventRequestForm.displayName = "EventRequestForm";
