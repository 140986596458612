import { FC, useState } from "react";
import { UseFormSetValue } from "react-hook-form";

import { ReactComponent as AddIcon } from "@src/assets/images/icons/add.svg";
import { ReactComponent as MinusIcon } from "@src/assets/images/icons/minus.svg";
import { Button } from "@src/components";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { GuestsListType } from "@src/interfaces/guest.interface";
import { theme } from "@src/theme";

import { Styled } from "./styles";

type CounterProps = {
  indexParent: number;
  setValue: UseFormSetValue<GuestsListType>;
  index: number;
  currCount: number;
  isMaximum: boolean;
  setEditing: (index: number, value: boolean) => void;
};

export const Counter: FC<CounterProps> = ({
  setEditing,
  currCount,
  setValue,
  indexParent,
  index,
  isMaximum,
  ...props
}) => {
  const [count, setCount] = useState(currCount);
  const { maxGuestCountText } = useGetBaseData();

  const handleIncrementClick = () => {
    setEditing(indexParent, true); // Указываем, что изменения происходят в этой комнате
    setCount((prev) => prev + 1);
    setValue(`guestList.${indexParent}.guests.${index}.count`, count + 1);
  };

  const isAdaptive = useMediaQuery(theme.breakpoints.medium);

  const handleDecreaseClick = () => {
    setEditing(indexParent, true);
    if (count === currCount) return;
    setCount((prev) => prev - 1);
    setValue(`guestList.${indexParent}.guests.${index}.count`, count - 1);
  };

  return (
    <>
      <Styled.Counter {...props}>
        <Button
          onClick={handleDecreaseClick}
          color={count === 0 || (index === 0 && count === currCount) ? theme.color.greyPale : theme.color.black}
          icon={<MinusIcon />}
          disabled={count === 0}
          iconAction
        />
        <Styled.CountValue>{count}</Styled.CountValue>
        <Styled.PlusContainer data-for={`guest-counter/${indexParent}`} data-tip={maxGuestCountText}>
          <Button
            onClick={handleIncrementClick}
            icon={<AddIcon />}
            iconAction
            disabled={isMaximum}
            color={isMaximum ? theme.color.greyPale : theme.color.black}
          />
        </Styled.PlusContainer>
        {isMaximum && !isAdaptive && <Styled.Tooltip id={`guest-counter/${indexParent}`} />}
      </Styled.Counter>
    </>
  );
};
