import { FC, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import Modal from "react-modal";

import { Counter } from "@src/components";
import { CounterPopup } from "@src/components/Counter/CounterPopup";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { GuestsListType, GuestsType } from "@src/interfaces/guest.interface";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";

import { Styled } from "./styles";

export type GuestCounterProps = {
  indexParent: number;
  setValue: UseFormSetValue<GuestsListType>;
  field: GuestsType;
  showDeleteButton: boolean;
  onRemoveClick: (index: number) => void;
  isReservationPage?: boolean;
  watchData: GuestsListType;
};
export const GuestCounter: FC<GuestCounterProps> = ({
  onRemoveClick,
  showDeleteButton,
  setValue,
  indexParent,
  field,
  isReservationPage,
  watchData,
}) => {
  const { roomText, deleteText, maxGuestCountText } = useGetBaseData();

  const [isMaximum, setIsMaximum] = useState(false);

  const isAdaptive = useMediaQuery(theme.breakpoints.medium);

  const GUESTS_MAX_VALUE = 6;
  const [modalStates, setModalStates] = useState<boolean[]>([]);
  const [activeModalIndex, setActiveModalIndex] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean[]>([]);

  useEffect(() => {
    setIsEditing(Array(watchData.guestList.length).fill(false));
  }, [watchData.guestList.length]);

  const setEditing = (index: number, value: boolean) => {
    setIsEditing((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };
  useEffect(() => {
    if (modalStates.length !== watchData.guestList.length) {
      setModalStates(Array(watchData.guestList.length).fill(false));
    }
  }, [watchData.guestList.length]);

  useEffect(() => {
    watchData.guestList.forEach((guestData, index) => {
      const totalGuestCount = guestData.guests.reduce((acc, item) => acc + item.count, 0);

      if (totalGuestCount >= GUESTS_MAX_VALUE && isAdaptive && isEditing[index] && activeModalIndex !== index) {
        setActiveModalIndex(index);
      }
    });
  }, [watchData, isAdaptive, isEditing]);

  useEffect(() => {
    const guestData = watchData.guestList[indexParent].guests;
    const totalGuestCount = guestData.reduce((acc, item) => acc + item.count, 0);
    setIsMaximum(totalGuestCount >= GUESTS_MAX_VALUE); // Логика уже есть
  }, [watchData, indexParent]);

  const handleCloseModal = () => {
    if (activeModalIndex !== null) {
      setEditing(activeModalIndex, false);
    }
    setActiveModalIndex(null);
  };

  const capitalizedRoomText = roomText ? roomText[0].toUpperCase() + roomText.slice(1) : "";

  return (
    <Styled.GuestCounterWrapper>
      <Styled.GuestCounterHeader>
        <Styled.GuestCounterRoomTitle>{`${capitalizedRoomText} ${indexParent + 1}`}</Styled.GuestCounterRoomTitle>
        {showDeleteButton && (
          <Styled.GuestDeleteButton onClick={() => onRemoveClick(indexParent)}>{deleteText}</Styled.GuestDeleteButton>
        )}
      </Styled.GuestCounterHeader>
      <Styled.GuestCounterContent isReservationPage={isReservationPage}>
        {field.guests.map((guest, index: number) => (
          <Styled.GuestCounterItem isReservationPage={isReservationPage} key={guest.id}>
            <Styled.GuestCounterTitleContainer>
              <Styled.GuestCounterTitle> {guest.name}</Styled.GuestCounterTitle>
              <Styled.GuestCounterText>{guest.age}</Styled.GuestCounterText>
            </Styled.GuestCounterTitleContainer>

            <Counter
              currCount={index === 0 ? 1 : 0}
              setValue={setValue}
              index={index}
              indexParent={indexParent}
              isMaximum={isMaximum}
              setEditing={setEditing}
            />
          </Styled.GuestCounterItem>
        ))}
        {isAdaptive && activeModalIndex === indexParent && (
          <Modal
            isOpen={true}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={popupStyles}>
            <CounterPopup text={maxGuestCountText} />
          </Modal>
        )}
      </Styled.GuestCounterContent>
    </Styled.GuestCounterWrapper>
  );
};
