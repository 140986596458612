import { useLocation } from "react-router-dom";

import { NavigationLink } from "@src/components/Footer/FooterLinks/types";
import { useGetBaseDataQuery } from "@src/store/services";

export const useGetNavigation = () => {
  const { data } = useGetBaseDataQuery();
  // change lang
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const linksRussian = [
    { text: data ? data?.lang_phrases.nav_booking : "", url: "/reservation/guests-counter" },
    { text: data ? data?.lang_phrases.actions_navigation : "", url: "/promo" },
    { text: data ? data?.lang_phrases.nav_services : "", url: "/rooms-and-services" },
    { text: data ? data?.lang_phrases.nav_long_stay : "", url: "/long-stay" },
    { text: data ? data?.lang_phrases.menu_event : "", url: "/events" },
    { text: data ? data?.lang_phrases.menu_infrastructure : "", url: "/infrastructure" },
    { text: data ? data?.lang_phrases.nav_contacts : "", url: "/contacts" },
    { text: data ? data?.lang_phrases.menu_weddings : "", url: "/weddings" },
    { text: data ? data?.lang_phrases.menu_spa : "", url: "/spa" },
    { text: data ? data?.lang_phrases.blog_navigation : "", url: "/blog" },
  ];
  const linksEnglish = [
    { text: data ? data?.lang_phrases.nav_booking : "", url: "/en/reservation/guests-counter" },
    { text: data ? data?.lang_phrases.actions_navigation : "", url: "/en/promo" },
    { text: data ? data?.lang_phrases.nav_services : "", url: "/en/rooms-and-services" },
    { text: data ? data?.lang_phrases.nav_long_stay : "", url: "/en/long-stay" },
    { text: data ? data?.lang_phrases.menu_event : "", url: "/en/events" },
    { text: data ? data?.lang_phrases.menu_infrastructure : "", url: "/en/infrastructure" },
    { text: data ? data?.lang_phrases.nav_contacts : "", url: "/en/contacts" },
    { text: data ? data?.lang_phrases.wedding_breadcrumb : "", url: "/en/weddings" },
    { text: data ? data?.lang_phrases.menu_spa : "", url: "/en/spa" },
    { text: data ? data?.lang_phrases.blog_navigation : "", url: "/en/blog" },
  ];

  const links: NavigationLink[] = !isEnglishVersion ? linksRussian : linksEnglish;

  return { links, isEnglishVersion };
};
