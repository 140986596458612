import { BaseData } from "@src/interfaces";
import { GuestsListType, GuestsType } from "@src/interfaces/guest.interface";
import { useGetBaseDataQuery } from "@src/store/services";

type ReturnedData = {
  data?: BaseData;
  arrivalDate: string;
  departureDate: string;
  bookingGuests: string;
  bookingText: string;
  datesStay: string;
  textNight: string;
  textNights: string;
  textNightsGenetiv: string;
  isLoading: boolean;
  phoneNumberText: string;
  phoneNumberTextSpa: string;
  requestCallText: string;
  contactsOrderCallSpa: string;
  requestCallIncorrectValue: string;
  addressText: string;
  reservingMailText: string;
  corporateClientsMailText: string;
  cooperationMailText: string;
  contactEmailSpa: string;
  callMeMailSpa: string;
  parkingText: string;
  contactSetRouteSpa: string;
  contactUndegroundParkingSpa: string;
  contactMainEntrainceSpa: string;
  mainEntranceText: string;
  userEmailLabel: string;
  getDirectionsText: string;
  reservationDepartmentLabel: string;
  corporationClientsLabel: string;
  cooperationLabel: string;
  contactsText: string;
  phoneText: string;
  contactPhoneSpa: string;
  commentText: string;
  deleteText: string;
  inOutTimeRules: string;
  bookingRules: string;
  roomText: string;
  roomsText: string;
  roomsTextGenetiv: string;
  addRoomText: string;
  bookingTitleText: string;
  buttonFindText: string;
  childrenText: string;
  paymentOfflineText: string;
  adultText: string;
  adultsText: string;
  underChildren0Text: string;
  underAdultsText: string;
  bookingCancelWarning: string;
  chooseButtonText: string;
  chooseBooking: string;
  chooseReserve: string;
  alreadyChooseText: string;
  guestsCount: GuestsType;
  guestsCountList: GuestsListType;
  underChildren6Text: string;
  resetText: string;
  cancelButton: string;
  hotelAddressLabel: string;
  hotelAddressLabelSpa: string;
  addressTextSpa: string;
  textFrom: string;
  noMoreRoomsText: string;
  arrivalText: string;
  departureText: string;
  userEmailError: string;
  guestDataTitle: string;
  paymentOnlineText: string;
  totalWord: string;
  rulesText: string;
  rulesLink: string;
  rulesLinkFile: string;
  paymentVariantsTitle: string;
  inOutTimeText: string;
  paymentWarning: string;
  paymentListText: string;
  userNameLabel: string;
  userLastNameLabel: string;
  userCitizenshipLabel: string;
  bookingTitle: string;
  validationName: string;
  validationLastname: string;
  validationEmail: string;
  validationPhone: string;
  guestText: string;
  guestsText: string;
  guestsAddText: string;
  pretextFor: string;
  userPhoneError: string;
  proposalS: string;
  userNameError: string;
  userLastNameError: string;
  commonErrorTextLink: string;
  commonErrorText: string;
  numberGuestsText: string;
  notificationSuccess: string;
  notificationError: string;
  unavailableDatesText: string;
  readyText: string;
  sendText: string;
  userComment: string;
  userFullName: string;
  userCitizenshipError: string;
  bookingWrongFormatError: string;
  emailTextTotalCost: string;
  emailTextPriceForNight: string;
  emailTextPrice: string;
  emailBreakfast: string;
  emailTaxes: string;
  emailLoyaltyDiscount: string;
  emailTextTotalPrice: string;
  checkOutText: string;
  checkInText: string;
  moreText: string;
  actionMoreText: string;
  textSuggestionFor: string;
  loyaltyNumberNotFount: string;
  loyaltyNumberNotFountText: string;
  adultsPluralText: string;
  oneChildText: string;
  oneChildGenetiveText: string;
  moreChildText: string;
  moreChildTextGenetive: string;
  roomIsUnavailable: string;
  roomIsUnavailableText: string;
  emailReservationNumber: string;
  currencyValue: string;
  additionalPlaceText: string;
  withoutPlaceText: string;
  maxGuestCountText: string;
  bookingCancelHeader: string;
  bookingCancelText: string;
  bookingCancelFeeText: string;
  bookingCantBeCancelled: string;
  bookingCancelButton: string;
  bookingAlreadyCancelledText: string;
  cancellationErrorText: string;
  cancellationSuccessText: string;
  toLateToCancelText: string;
  toLateToCancelText24hrs: string;
  cancellationLinkErrorText: string;
  refundErrorText: string;

  weddingsPageTitle: string;
  weddingsMenuText: string;
  weddingsBreadcrumb: string;
  weddingsCardRequestText: string;
  weddingsTypeTitle: string;
  weddingsRequestBtnText: string;
  weddingsHallsTitle: string;
  weddingsHallsBookBtn: string;
  weddingsGalleryBtnText: string;
  weddingsFormRecipientAddress: string;
  weddingsFormTitle: string;
  weddingsFormName: string;
  weddingsFormPhone: string;
  weddingsFormEmail: string;
  weddingsFormMembers: string;
  weddingsFormDate: string;
  weddingsFormLocation: string;
  weddingsFormComment: string;
  weddingsReqFieldsError: string;
  mainPageTitle: string;
  longStayPageTitle: string;

  actionsPageTitle: string;
  anotherActionsText: string;
  actionsNavText: string;
  actionsBreadcrumbsText: string;
  actionsRequestCallText: string;
  actionsRequestCallTitle: string;
  searchRoomError: string;

  blogPageTitle: string;
  blogAnotherTitle: string;
  blogBreadcrumbs: string;
  blogCallText: string;
  blogMoreText: string;
  blogFormTitle: string;
  contactsHRLabel: string;
  contactsHREmail: string;

  bookingConfirmedText: string;
  bookingFullPriceText: string;
  infrastructureCallText: string;
  infrastructureHeaderText: string;
  infrastructureBreadcrumpText: string;
  infrastructureMenuText: string;
  roomsDecl: string[];
  guestsDecl: string[];

  eventsPageTitle: string;
  eventsRequestBtnText: string;
  eventsHallText: string;
  eventsHallBookText: string;
  eventsHallGalleryText: string;
  eventsTableHallText: string;
  eventsTableSizeText: string;
  eventsTableAreaText: string;
  eventsTableHeightText: string;
  eventsFormTitleText: string;
  eventsFormLastnameText: string;
  eventsFormNameText: string;
  eventsFormPhoneText: string;
  eventsFormEmailText: string;
  eventsFormMembersText: string;
  eventsFormDateText: string;
  eventsFormRoomText: string;
  eventsFormCommentText: string;
  eventsFormErrorText: string;
  eventsTypeBookText: string;
  eventIncorrectEmailError: string;
  eventIncorrectPhoneError: string;
  eventParticipantsError: string;
  eventDateError: string;
  aboutHotelNavText: string;
  bookingChooseDateText: string;
  bookingResetBtnText: string;
  eventSendFormBtnText: string;
  eventNavHeaderText: string;
  bookingPaymentText: string;
  bookingChoosePlanText: string;
  bookingRulesTitle: string;
  bookingFormDataCheckboxText: string;
  bookingLoyaltyInputText: string;
  bookingLoyaltyInputRequiredText: string;
  bookingOnlinePaymentBtnText: string;
  bookingOfflinePaymentBtnText: string;

  spaSignUpOnline: string;
  spaAboutTitle: string;
  spaAboutText: string;
  spaInfrastractureTitle: string;
  spaMenuTitle: string;
  spaServicesTitle: string;
  spaPromoTitle: string;
  spaOtherServices: string;
  spaRequestCall: string;
  spaRequestCallFormTitle: string;
  spaMoreText: string;
  spaWorkingHoursTitle: string;
  spaWorkingHoursValue: string;

  rulesCallStartText: string;
  rulesBookingStartText: string;
  rulesSecondText: string;
  rulesPrivacyPolicyLink: string;
  rulesPrivacyPolicyFile: string;
  rulesPersonalDataLink: string;
  rulesPersonalDataFile: string;
};

export const useGetBaseData = (): ReturnedData => {
  const { data, isLoading } = useGetBaseDataQuery();

  const emailTaxes = data ? data?.lang_phrases.email_taxes : "";
  const checkInText = data ? data?.lang_phrases.check_in : "";
  const textSuggestionFor = data ? data?.lang_phrases.text_suggestion_for : "";
  const loyaltyNumberNotFount = data ? data?.lang_phrases.loyalty_number_not_fount_error_title : "";
  const loyaltyNumberNotFountText = data ? data?.lang_phrases.loyalty_number_not_fount_error_text : "";
  const moreText = data ? data?.lang_phrases.more : "";
  const actionMoreText = data ? data?.lang_phrases.actions_more : "";
  const checkOutText = data ? data?.lang_phrases.check_out : "";
  const emailTextTotalPrice = data ? data?.lang_phrases.email_text_total_price : "";
  const emailLoyaltyDiscount = data ? data?.lang_phrases.email_loyalty_discount : "";
  const emailTextPriceForNight = data ? data?.lang_phrases.email_text_price_for_night : "";
  const emailTextPrice = data ? data?.lang_phrases.email_text_price : "";
  const emailBreakfast = data ? data?.lang_phrases.email_breakfast : "";
  const commonErrorTextLink = data ? data?.lang_phrases.common_error_text_link : "";
  const userComment = data ? data?.lang_phrases.user_comment : "";
  const userFullName = data ? data?.lang_phrases.user_fio : "";
  const userCitizenshipError = data ? data?.lang_phrases.user_citizenship_error : "";
  const bookingWrongFormatError = data ? data?.lang_phrases.booking_incorrect_symbols : "";
  const emailTextTotalCost = data ? data?.lang_phrases.email_text_total_cost : "";
  const arrivalDate = data ? data?.lang_phrases.arrival_date : "";
  const departureDate = data ? data?.lang_phrases.departure_date : "";
  const bookingGuests = data ? data?.lang_phrases.booking_guests : "";
  const bookingText = data ? data?.lang_phrases.booking_text : "";
  const datesStay = data ? data?.lang_phrases.dates_stay : "";
  const textNight = data ? data?.lang_phrases.text_night : "";
  const textNightsGenetiv = data ? data?.lang_phrases.text_nights : "";
  const textNights = data ? data?.lang_phrases.email_text_nights : "";
  const requestCallText = data ? data?.lang_phrases.request_call : "";
  const contactsOrderCallSpa = data ? data?.lang_phrases.contacts_order_call_spa : "";
  const requestCallIncorrectValue = data ? data?.lang_phrases.call_incorrect_symbols : "";
  const phoneNumberText = data ? data?.lang_phrases.site_phone : "";
  const phoneNumberTextSpa = data ? data?.lang_phrases.contacts_value_phone_spa : "";
  const addressText = data ? data?.lang_phrases.hotel_address : "";
  const reservingMailText = data ? data?.lang_phrases.reservation_department : "";
  const corporateClientsMailText = data ? data?.lang_phrases.corporation_clients : "";
  const cooperationMailText = data ? data?.lang_phrases.cooperation : "";
  const contactEmailSpa = data ? data?.lang_phrases.contacts_email_spa : "";
  const callMeMailSpa = data ? data?.lang_phrases.call_me_mail_recipient_spa : "";
  const parkingText = data ? data?.lang_phrases.underground_parking : "";
  const contactSetRouteSpa = data ? data?.lang_phrases.contacts_set_route_spa : "";
  const contactUndegroundParkingSpa = data ? data?.lang_phrases.contacts_underground_parking_spa : "";
  const contactMainEntrainceSpa = data ? data?.lang_phrases.contacts_main_entrance_spa : "";
  const mainEntranceText = data ? data?.lang_phrases.main_entrance : "";
  const getDirectionsText = data ? data?.lang_phrases.get_directions : "";
  const reservationDepartmentLabel = data ? data?.lang_phrases.reservation_department_label : "";
  const corporationClientsLabel = data ? data?.lang_phrases.corporation_clients_label : "";
  const cooperationLabel = data ? data?.lang_phrases.cooperation_label : "";
  const contactsText = data ? data?.lang_phrases.nav_contacts : "";
  const phoneText = data ? data?.lang_phrases.information_centre_label : "";
  const contactPhoneSpa = data ? data?.lang_phrases.contacts_phone_spa : "";
  const commentText = data ? data?.lang_phrases.user_comment : "";
  const deleteText = data ? data?.lang_phrases.text_delete : "";
  const roomText = data ? data?.lang_phrases.text_room.toLowerCase() : "";
  const roomsText = data ? data?.lang_phrases.text_rooms : "";
  const roomsTextGenetiv = data ? data?.lang_phrases.text_rooms_additional : "";

  const addRoomText = data ? data?.lang_phrases.add_room : "";
  const bookingTitleText = data ? data?.lang_phrases.booking_title : "";
  const buttonFindText = data ? data?.lang_phrases.button_find : "";
  const chooseButtonText = data ? data?.lang_phrases.choose_button : "";
  const chooseBooking = data ? data?.lang_phrases.choose_booking : "";
  const chooseReserve = data ? data?.lang_phrases.choose_reserve : "";

  const childrenText = data ? data?.lang_phrases.text_children : "";
  const underChildren0Text = data ? data?.lang_phrases.text_under_children_0 : "";
  const underChildren6Text = data ? data?.lang_phrases.text_under_children_6 : "";
  const underAdultsText = data ? data?.lang_phrases.text_under_adults : "";
  const bookingCancelWarning = data ? data?.lang_phrases.booking_cancel_warning : "";
  const alreadyChooseText = data ? data?.lang_phrases.already_choose : "";
  const guestDataTitle = data ? data?.lang_phrases.guest_data_title : "";
  const totalWord = data ? data?.lang_phrases.total_word : "";
  const rulesText = data ? data?.lang_phrases.rules_text : "";
  const rulesLink = data ? data?.lang_phrases.rules_link : "";
  const rulesLinkFile = data ? data?.lang_phrases.rules_link_file : "";

  const rulesCallStartText = data ? data?.lang_phrases.before_privacy_policy_text : "";
  const rulesBookingStartText = data ? data?.lang_phrases.before_processing_personal_data_second_text : "";
  const rulesSecondText = data ? data?.lang_phrases.before_processing_personal_data_text : "";

  const rulesPrivacyPolicyLink = data ? data?.lang_phrases.privacy_policy_link_text : "";
  const rulesPrivacyPolicyFile = data ? data?.lang_phrases.privacy_policy_link : "";
  const rulesPersonalDataLink = data ? data?.lang_phrases.processing_personal_data_link_text : "";
  const rulesPersonalDataFile = data ? data?.lang_phrases.processing_personal_data_link : "";
  const userLastNameLabel = data ? data?.lang_phrases.user_lastname : "";
  const userNameLabel = data ? data?.lang_phrases.user_name : "";
  const userCitizenshipLabel = data ? data?.lang_phrases.user_citizenship : "";
  const userEmailLabel = data ? data?.lang_phrases.user_email : "";
  const inOutTimeText = data ? data?.lang_phrases.in_out_time : "";
  const paymentVariantsTitle = data ? data?.lang_phrases.payment_variants_title : "";
  const inOutTimeRules = data ? data?.lang_phrases.in_out_time_rules : "";
  const bookingRules = data ? data?.lang_phrases.booking_rules_text : "";
  const paymentOfflineText = data ? data?.lang_phrases.payment_offline : "";
  const paymentOnlineText = data ? data?.lang_phrases.payment_online : "";
  const paymentWarning = data ? data?.lang_phrases.payment_warning : "";
  const paymentListText = data ? data?.lang_phrases.payment_list : "";
  const bookingTitle = data ? data?.lang_phrases.booking_title : "";
  const cancelButton = data ? data?.lang_phrases.cancel_button : "";
  const hotelAddressLabel = data ? data?.lang_phrases.hotel_address_label : "";
  const hotelAddressLabelSpa = data ? data?.lang_phrases.contacts_title_address_spa : "";
  const addressTextSpa = data ? data?.lang_phrases.contacts_address_spa : "";
  const textFrom = data ? data?.lang_phrases.text_from : "";
  const noMoreRoomsText = data ? data?.lang_phrases.no_more_rooms_on_this_period : "";

  const validationName = data ? data?.lang_phrases.user_name_error : "";
  const validationLastname = data ? data?.lang_phrases.user_lastname_error : "";
  const validationEmail = data ? data?.lang_phrases.user_email_error : "";
  const validationPhone = data ? data?.lang_phrases.user_phone_error : "";

  const notificationSuccess = data ? data?.lang_phrases.notification_success_sent : "";
  const notificationError = data ? data?.lang_phrases.notification_connection_error : "";

  const readyText = data ? data?.lang_phrases.text_ready : "";
  const unavailableDatesText = data ? data?.lang_phrases.dates_cannot_booked : "";
  const guestText = data ? data?.lang_phrases.text_guest : "";
  const guestsText = data ? data?.lang_phrases.text_guests : "";
  const guestsAddText = data ? data?.lang_phrases.text_guests_additional : "";
  const proposalS = data ? data?.lang_phrases.proposal_s : "";
  const pretextFor = data ? data?.lang_phrases.pretext_for : "";
  const userLastNameError = data ? data?.lang_phrases.user_lastname_error : "";
  const userNameError = data ? data?.lang_phrases.user_name_error : "";
  const userEmailError = data ? data?.lang_phrases.user_email_error : "";
  const userPhoneError = data ? data?.lang_phrases.user_phone_error : "";
  const commonErrorText = data ? data?.lang_phrases.common_error_text : "";
  const numberGuestsText = data ? data?.lang_phrases.number_guests : "";
  const adultText = data ? data?.lang_phrases.adults_text_in_one : "";
  const adultsText = data ? data?.lang_phrases.text_adults : ""; //Взрослые
  const adultsPluralText = data ? data?.lang_phrases.adults_text_in_many : ""; //взрослых
  const oneChildText = data ? data?.lang_phrases.child_word : ""; //ребенок
  const oneChildGenetiveText = data ? data?.lang_phrases.child_word_genitive : ""; //ребенка
  const moreChildText = data ? data?.lang_phrases.child_word_in_many : ""; //дети
  const moreChildTextGenetive = data ? data?.lang_phrases.child_word_in_many_dative : ""; //детей
  const roomIsUnavailable = data ? data?.lang_phrases.not_available_anymore_error_title : "";
  const roomIsUnavailableText = data ? data?.lang_phrases.not_available_anymore_error_text : "";
  const emailReservationNumber = data ? data?.lang_phrases.email_reservation_number : "";
  const currencyValue = data ? data?.lang_phrases.currency_symbol : "";
  const additionalPlaceText = data ? data?.lang_phrases.on_additional_place : "";
  const withoutPlaceText = data ? data?.lang_phrases.without_additional_place : "";
  const maxGuestCountText = data ? data?.lang_phrases.max_guest_count_error : "";
  const bookingCancelHeader = data ? data?.lang_phrases.refund_text_front_header : "";
  const bookingCancelText = data ? data?.lang_phrases.refund_text_front_body : "";
  const bookingCancelFeeText = data ? data?.lang_phrases.front_refund_money_fine : "";
  const bookingCantBeCancelled = data ? data?.lang_phrases.front_cant_be_canceled : "";
  const bookingCancelButton = data ? data?.lang_phrases.refund_text_front_button : "";
  const bookingAlreadyCancelledText = data ? data?.lang_phrases.refund_validation_canceled : "";
  const cancellationErrorText = data ? data?.lang_phrases.refund_validation_error_title : "";
  const cancellationSuccessText = data ? data?.lang_phrases.refund_validation_success : "";
  const toLateToCancelText = data ? data?.lang_phrases.refund_validation_late : "";
  const toLateToCancelText24hrs = data ? data?.lang_phrases.refund_validation_late_24_hours : "";
  const cancellationLinkErrorText = data ? data?.lang_phrases.refund_validation_invalid : "";
  const refundErrorText = data ? data?.lang_phrases.refund_validation_error : "";
  // email
  const bookingConfirmedText = data ? data?.lang_phrases.email_title : "";
  const bookingFullPriceText = data ? data?.lang_phrases.email_total_price : "";

  const infrastructureCallText = data ? data?.lang_phrases.name_card_button_infrastructure : "";
  const infrastructureHeaderText = data ? data?.lang_phrases.head_infrastructure : "";
  const infrastructureBreadcrumpText = data ? data?.lang_phrases.breadcrumbs_infrastructure : "";
  const infrastructureMenuText = data ? data?.lang_phrases.menu_infrastructure : "";
  // мероприятия
  const eventsPageTitle = data ? data?.lang_phrases.event_title : "";
  const eventsRequestBtnText = data ? data?.lang_phrases.event_request_button : "";
  const eventsHallText = data ? data?.lang_phrases.event_hall : "";
  const eventsHallBookText = data ? data?.lang_phrases.event_hall_book : "";
  const eventsHallGalleryText = data ? data?.lang_phrases.event_hall_gallery : "";
  const eventsTableHallText = data ? data?.lang_phrases.event_table_hall : "";
  const eventsTableSizeText = data ? data?.lang_phrases.event_table_size : "";
  const eventsTableAreaText = data ? data?.lang_phrases.event_table_area : "";
  const eventsTableHeightText = data ? data?.lang_phrases.event_table_height : "";
  const eventsFormTitleText = data ? data?.lang_phrases.event_request : "";
  const eventsFormLastnameText = data ? data?.lang_phrases.event_form_lastname : "";
  const eventsFormNameText = data ? data?.lang_phrases.event_form_name : "";
  const eventsFormPhoneText = data ? data?.lang_phrases.event_form_phone : "";
  const eventsFormEmailText = data ? data?.lang_phrases.event_form_email : "";
  const eventsFormMembersText = data ? data?.lang_phrases.event_form_members : "";
  const eventsFormDateText = data ? data?.lang_phrases.event_form_date : "";
  const eventsFormRoomText = data ? data?.lang_phrases.event_form_room : "";
  const eventsFormCommentText = data ? data?.lang_phrases.event_form_comment : "";
  const eventsFormErrorText = data ? data?.lang_phrases.event_form_fields : "";
  const eventsTypeBookText = data ? data?.lang_phrases.events_call_request : "";
  const eventIncorrectEmailError = data ? data?.lang_phrases.even_email_error : "";
  const eventIncorrectPhoneError = data ? data?.lang_phrases.even_phone_error : "";
  const eventParticipantsError = data ? data?.lang_phrases.even_guest_count_error : "";
  const eventDateError = data ? data?.lang_phrases.even_date_error : "";
  const eventSendFormBtnText = data ? data?.lang_phrases.event_send_form_button : "";
  const eventNavHeaderText = data ? data?.lang_phrases.menu_event_header : "";

  const aboutHotelNavText = data ? data?.lang_phrases.menu_about : "";

  const bookingChooseDateText = data ? data?.lang_phrases.booking_choose_date : "";
  const bookingResetBtnText = data ? data?.lang_phrases.booking_reset_button : "";
  const bookingPaymentText = data ? data?.lang_phrases.booking_pay_text_under_price : "";
  const bookingChoosePlanText = data ? data?.lang_phrases.booking_choose_plan_text : "";
  const bookingRulesTitle = data ? data?.lang_phrases.booking_rules_title : "";
  const bookingFormDataCheckboxText = data ? data?.lang_phrases.booking_use_this_data_for_all : "";
  const bookingLoyaltyInputText = data
    ? data?.lang_phrases.booking_form_loyalty_number_field.replace(/<\/?[a-zA-Z]+>/gi, "")
    : "";
  const bookingLoyaltyInputRequiredText = data ? data?.lang_phrases.booking_loyalty_number_required : "";
  const bookingOnlinePaymentBtnText = data ? data?.lang_phrases.booking_online_payment : "";
  const bookingOfflinePaymentBtnText = data ? data?.lang_phrases.booking_upon_arrival_payment : "";

  // weddings
  const weddingsPageTitle = data ? data?.lang_phrases.wedding_title : "";
  const weddingsMenuText = data ? data?.lang_phrases.menu_weddings : "";
  const weddingsBreadcrumb = data ? data?.lang_phrases.wedding_breadcrumb : "";
  const weddingsCardRequestText = data ? data?.lang_phrases.wedding_request_button_on_card : "";
  const weddingsTypeTitle = data ? data?.lang_phrases.wedding_type_title : "";
  const weddingsRequestBtnText = data ? data?.lang_phrases.wedding_request_button : "";
  const weddingsHallsTitle = data ? data?.lang_phrases.wedding_title_hall : "";
  const weddingsHallsBookBtn = data ? data?.lang_phrases.wedding_hall_book : "";
  const weddingsGalleryBtnText = data ? data?.lang_phrases.wedding_hall_gallery : "";
  const weddingsFormRecipientAddress = data ? data?.lang_phrases.wedding_form_recipient : "";
  const weddingsFormTitle = data ? data?.lang_phrases.wedding_form_title : "";
  const weddingsFormName = data ? data?.lang_phrases.wedding_form_name : "";
  const weddingsFormPhone = data ? data?.lang_phrases.wedding_form_phone : "";
  const weddingsFormEmail = data ? data?.lang_phrases.wedding_form_email : "";
  const weddingsFormMembers = data ? data?.lang_phrases.wedding_form_members : "";
  const weddingsFormDate = data ? data?.lang_phrases.wedding_form_date : "";
  const weddingsFormLocation = data ? data?.lang_phrases.wedding_form_location : "";
  const weddingsFormComment = data ? data?.lang_phrases.wedding_form_comment : "";
  const weddingsReqFieldsError = data ? data?.lang_phrases.wedding_form_fields : "";
  const mainPageTitle = data ? data?.lang_phrases.main_page_title : "";
  const longStayPageTitle = data ? data?.lang_phrases.long_stay_title_h1 : "";

  // акции
  const actionsPageTitle = data ? data?.lang_phrases.actions_title : "";
  const anotherActionsText = data ? data?.lang_phrases.actions_another : "";
  const actionsNavText = data ? data?.lang_phrases.actions_navigation : "";
  const actionsBreadcrumbsText = data ? data?.lang_phrases.actions_breadcrumbs : "";
  const actionsRequestCallText = data ? data?.lang_phrases.actions_call : "";
  const actionsRequestCallTitle = data ? data?.lang_phrases.actions_form_title : "";

  // spa
  const spaSignUpOnline = data ? data?.lang_phrases.sign_up_online_spa : "";
  const spaAboutTitle = data ? data?.lang_phrases.about_us_title_spa : "";
  const spaAboutText = data ? data?.lang_phrases.about_us_text_spa : "";
  const spaInfrastractureTitle = data ? data?.lang_phrases.infrastructure_title_spa : "";
  const spaMenuTitle = data ? data?.lang_phrases.spa_menu_title_spa : "";
  const spaServicesTitle = data ? data?.lang_phrases.services_title_spa : "";
  const spaPromoTitle = data ? data?.lang_phrases.promotions_title_spa : "";
  const spaOtherServices = data ? data?.lang_phrases.services_spa_another : "";
  const spaRequestCall = data ? data?.lang_phrases.service_spa_call : "";
  const spaRequestCallFormTitle = data ? data?.lang_phrases.services_form_title : "";
  const spaMoreText = data ? data?.lang_phrases.services_spa_more : "";
  const spaWorkingHoursTitle = data ? data?.lang_phrases.operating_mode_contacts_spa_title : "";
  const spaWorkingHoursValue = data ? data?.lang_phrases.operating_mode_contacts_spa : "";

  //блог
  const blogPageTitle = data ? data?.lang_phrases.blog_title : "";
  const blogAnotherTitle = data ? data?.lang_phrases.blog_another : "";
  const blogBreadcrumbs = data ? data?.lang_phrases.blog_breadcrumbs : "";
  const blogCallText = data ? data?.lang_phrases.blog_call : "";
  const blogMoreText = data ? data?.lang_phrases.blog_more : "";
  const blogFormTitle = data ? data?.lang_phrases.blog_form_title : "";

  const contactsHRLabel = data ? data?.lang_phrases.hr_contacts_label : "";
  const contactsHREmail = data ? data?.lang_phrases.hr_contacts_email : "";
  const searchRoomError = data ? data?.lang_phrases.search_error : "";

  const sendText = data ? data?.lang_phrases.call_me_send_button : "";
  const resetText = "Сбросить";
  const arrivalText = "Заезд";
  const departureText = "Выезд";

  const guestsCount = {
    roomNumber: 1,
    guests: [
      { id: 1, type: "adult_count", name: adultsText, age: underAdultsText, count: 1 },
      { id: 3, type: "child_under_11_count", name: childrenText, age: underChildren6Text, count: 0 },
      { id: 2, type: "child_under_5_count", name: childrenText, age: underChildren0Text, count: 0 },
    ],
  };

  const roomsDecl = [roomText, roomsText, roomsTextGenetiv];
  const guestsDecl = [guestText, guestsText, guestsAddText];

  const guestsCountList = {
    guestList: [guestsCount],
  };

  return {
    data,
    moreText,
    underChildren6Text,
    emailTaxes,
    hotelAddressLabel,
    hotelAddressLabelSpa,
    addressTextSpa,
    emailLoyaltyDiscount,
    emailBreakfast,
    emailTextPriceForNight,
    emailTextPrice,
    emailTextTotalCost,
    textSuggestionFor,
    userPhoneError,
    commonErrorText,
    userComment,
    userFullName,
    userCitizenshipError,
    bookingWrongFormatError,
    checkInText,
    pretextFor,
    userLastNameError,
    proposalS,
    loyaltyNumberNotFountText,
    userNameError,
    userEmailError,
    textFrom,
    noMoreRoomsText,
    cancelButton,
    inOutTimeRules,
    bookingRules,
    userLastNameLabel,
    inOutTimeText,
    bookingTitle,
    emailTextTotalPrice,
    userEmailLabel,
    paymentOfflineText,
    userCitizenshipLabel,
    paymentVariantsTitle,
    commonErrorTextLink,
    userNameLabel,
    paymentWarning,
    rulesText,
    rulesLink,
    rulesLinkFile,
    totalWord,
    guestDataTitle,
    arrivalDate,
    loyaltyNumberNotFount,
    departureDate,
    paymentListText,
    bookingGuests,
    bookingText,
    datesStay,
    textNight,
    textNights,
    textNightsGenetiv,
    mainEntranceText,
    parkingText,
    cooperationMailText,
    contactEmailSpa,
    callMeMailSpa,
    corporateClientsMailText,
    reservingMailText,
    addressText,
    isLoading,
    phoneNumberText,
    requestCallText,
    requestCallIncorrectValue,
    getDirectionsText,
    reservationDepartmentLabel,
    corporationClientsLabel,
    cooperationLabel,
    contactsText,
    phoneText,
    commentText,
    childrenText,
    underChildren0Text,
    underAdultsText,
    adultText,
    adultsText,
    guestsCountList,
    guestsCount,
    deleteText,
    roomText,
    roomsText,
    roomsTextGenetiv,
    paymentOnlineText,
    addRoomText,
    bookingTitleText,
    buttonFindText,
    bookingCancelWarning,
    chooseButtonText,
    chooseBooking,
    chooseReserve,
    alreadyChooseText,
    resetText,
    checkOutText,
    arrivalText,
    departureText,
    validationName,
    validationLastname,
    validationEmail,
    validationPhone,
    guestText,
    guestsText,
    numberGuestsText,
    notificationSuccess,
    notificationError,
    unavailableDatesText,
    readyText,
    sendText,
    adultsPluralText,
    oneChildText,
    oneChildGenetiveText,
    moreChildText,
    moreChildTextGenetive,
    roomIsUnavailable,
    roomIsUnavailableText,
    emailReservationNumber,
    currencyValue,
    additionalPlaceText,
    withoutPlaceText,
    guestsAddText,
    maxGuestCountText,
    bookingCancelHeader,
    bookingCancelText,
    bookingCancelFeeText,
    bookingCantBeCancelled,
    bookingCancelButton,
    bookingAlreadyCancelledText,
    cancellationErrorText,
    cancellationSuccessText,
    toLateToCancelText,
    toLateToCancelText24hrs,
    cancellationLinkErrorText,
    refundErrorText,

    weddingsPageTitle,
    weddingsMenuText,
    weddingsBreadcrumb,
    weddingsCardRequestText,
    weddingsTypeTitle,
    weddingsRequestBtnText,
    weddingsHallsTitle,
    weddingsHallsBookBtn,
    weddingsGalleryBtnText,
    weddingsFormRecipientAddress,
    weddingsFormTitle,
    weddingsFormName,
    weddingsFormPhone,
    weddingsFormEmail,
    weddingsFormMembers,
    weddingsFormDate,
    weddingsFormLocation,
    weddingsFormComment,
    weddingsReqFieldsError,
    blogPageTitle,
    blogAnotherTitle,
    blogBreadcrumbs,
    blogCallText,
    blogMoreText,
    blogFormTitle,

    actionsPageTitle,
    anotherActionsText,
    actionsNavText,
    actionsBreadcrumbsText,
    actionsRequestCallText,
    actionsRequestCallTitle,
    actionMoreText,
    searchRoomError,
    mainPageTitle,
    longStayPageTitle,
    bookingConfirmedText,
    bookingFullPriceText,
    infrastructureCallText,
    infrastructureHeaderText,
    infrastructureBreadcrumpText,
    infrastructureMenuText,
    roomsDecl,
    guestsDecl,
    eventsPageTitle,
    eventsRequestBtnText,
    eventsHallText,
    eventsHallBookText,
    eventsHallGalleryText,
    eventsTableHallText,
    eventsTableSizeText,
    eventsTableAreaText,
    eventsTableHeightText,
    eventsFormTitleText,
    eventsFormLastnameText,
    eventsFormNameText,
    eventsFormPhoneText,
    eventsFormEmailText,
    eventsFormMembersText,
    eventsFormDateText,
    eventsFormRoomText,
    eventsFormCommentText,
    eventsFormErrorText,
    eventsTypeBookText,
    eventIncorrectEmailError,
    eventIncorrectPhoneError,
    eventParticipantsError,
    eventDateError,
    aboutHotelNavText,
    bookingChooseDateText,
    bookingResetBtnText,
    eventSendFormBtnText,
    eventNavHeaderText,
    bookingPaymentText,
    bookingChoosePlanText,
    bookingRulesTitle,
    bookingFormDataCheckboxText,
    bookingLoyaltyInputText,
    bookingLoyaltyInputRequiredText,
    bookingOnlinePaymentBtnText,
    bookingOfflinePaymentBtnText,
    spaSignUpOnline,
    spaAboutTitle,
    spaAboutText,
    spaInfrastractureTitle,
    spaMenuTitle,
    spaServicesTitle,
    spaPromoTitle,
    spaOtherServices,
    spaRequestCall,
    spaRequestCallFormTitle,
    spaMoreText,
    spaWorkingHoursTitle,
    spaWorkingHoursValue,
    contactPhoneSpa,
    contactsOrderCallSpa,
    contactSetRouteSpa,
    contactUndegroundParkingSpa,
    contactMainEntrainceSpa,
    phoneNumberTextSpa,

    rulesCallStartText,
    rulesBookingStartText,
    rulesSecondText,
    rulesPrivacyPolicyLink,
    rulesPrivacyPolicyFile,
    rulesPersonalDataLink,
    rulesPersonalDataFile,
    contactsHRLabel,
    contactsHREmail,
  };
};
