import { FC, useState } from "react";
import Modal from "react-modal";

import placemarkSpaIcon from "@src/assets/images/icons/placemark-spa.svg";
import placemarkIcon from "@src/assets/images/icons/placemark.svg";
import { ReactComponent as OleaIcon } from "@src/assets/svg/olea-icon.svg";
import { useGetBaseData, useMediaQuery } from "@src/hooks";
import { useGetMenuData } from "@src/hooks/useGetMenuData";
import { popupStyles } from "@src/styles/popupStyles";
import { theme } from "@src/theme";
import { numberWithSpaces } from "@src/utils";

import { Button } from "../_Ui";
import { YMap } from "../Map";
import { RequestCallForm } from "../RequestCallForm";
import { TextLabel } from "../TextLabel";
import { MAP_MARKER_DESKTOP, MAP_MARKER_MOBILE, MAP_ZOOM_DESKTOP, MAP_ZOOM_MOBILE } from "./constants";
import { Styled } from "./styles";

interface ContactsProps {
  isSpaPage?: boolean;
}

export const Contacts: FC<ContactsProps> = ({ isSpaPage = false }) => {
  const {
    mainEntranceText,
    parkingText,
    contactSetRouteSpa,
    contactUndegroundParkingSpa,
    contactMainEntrainceSpa,
    cooperationMailText,
    contactEmailSpa,
    callMeMailSpa,
    corporateClientsMailText,
    reservingMailText,
    addressText,
    hotelAddressLabelSpa,
    addressTextSpa,
    isLoading,
    phoneNumberText,
    phoneNumberTextSpa,
    requestCallText,
    contactsOrderCallSpa,
    getDirectionsText,
    reservationDepartmentLabel,
    corporationClientsLabel,
    cooperationLabel,
    phoneText,
    contactPhoneSpa,
    hotelAddressLabel,
    spaWorkingHoursTitle,
    spaWorkingHoursValue,
    contactsHRLabel,
    contactsHREmail,
  } = useGetBaseData();

  const { contactsText, contactsTitleSpa } = useGetMenuData();

  const [isOpen, setIsOpen] = useState(false);

  const isAdaptive = useMediaQuery(theme.breakpoints.large);
  const mobileMapHeight = "563px";
  const YMapHeight = !isAdaptive ? "100%" : mobileMapHeight;
  const YMapZoom = isAdaptive ? MAP_ZOOM_MOBILE : MAP_ZOOM_DESKTOP;
  const YMapCenter = isAdaptive ? MAP_MARKER_MOBILE : MAP_MARKER_DESKTOP;

  const formattedPhoneNumber = phoneNumberText && numberWithSpaces(phoneNumberText, "## ### ### ## ##");
  const formattedPhoneNumberSpa = phoneNumberTextSpa && numberWithSpaces(phoneNumberTextSpa, "## ### ### ## ##");

  const handleRequestCallClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClosePopupClick = () => {
    setIsOpen(false);
  };

  // geolocation
  const handleParkingBtnClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = `${position.coords.latitude}, ${position.coords.longitude}`;
        const parkingLink = `https://yandex.ru/maps/193/voronezh/?ll=39.206992%2C51.669149&mode=routes&rtext=${currentLocation}~51.668033%2C39.206559&rtt=auto&ruri=~&z=17.05`;
        window.open(parkingLink, "_blank");
      });
    } else {
      const parkingLink =
        "https://yandex.ru/maps/193/voronezh/?ll=39.206992%2C51.669149&mode=routes&rtext=51.668803%2C39.207514~51.668033%2C39.206559&rtt=auto&ruri=~&z=17.05";
      window.open(parkingLink, "_blank");
    }
  };
  const handleEntranceBtnClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = `${position.coords.latitude}, ${position.coords.longitude}`;
        const entranceLink = `https://yandex.ru/maps/193/voronezh/?ll=39.205969%2C51.668007&mode=routes&rtext=${currentLocation}~51.667870%2C39.206503&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Nzg3MzEyMhJF0KDQvtGB0YHQuNGPLCDQktC-0YDQvtC90LXQtiwg0L_RgNC-0YHQv9C10LrRgiDQoNC10LLQvtC70Y7RhtC40LgsIDM4IgoNdtMcQhXmq05C&z=17`;
        window.open(entranceLink, "_blank");
      });
    } else {
      const entranceLink =
        "https://yandex.ru/maps/193/voronezh/?ll=39.205969%2C51.668007&mode=routes&rtext=51.668199%2C39.203978~51.667870%2C39.206503&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Nzg3MzEyMhJF0KDQvtGB0YHQuNGPLCDQktC%2B0YDQvtC90LXQtiwg0L%2FRgNC%2B0YHQv9C10LrRgiDQoNC10LLQvtC70Y7RhtC40LgsIDM4IgoNdtMcQhXmq05C&z=17";
      window.open(entranceLink, "_blank");
    }
  };

  const placemark = isSpaPage ? placemarkSpaIcon : placemarkIcon;

  const phoneNumberValue = isSpaPage ? formattedPhoneNumberSpa : formattedPhoneNumber;
  const contactsTitle = isSpaPage ? contactsTitleSpa : contactsText;
  const phoneTitle = isSpaPage ? contactPhoneSpa : phoneText;
  const requestCallTextTitle = isSpaPage ? contactsOrderCallSpa : requestCallText;
  const directionTitle = isSpaPage ? contactSetRouteSpa : getDirectionsText;

  return (
    <>
      <Styled.Container>
        <Styled.Header>{contactsTitle}</Styled.Header>
        {isLoading ? (
          "Loading..."
        ) : (
          <Styled.ContactsWrapper>
            <Styled.Contacts>
              <TextLabel blue={isSpaPage} label={phoneTitle} text={phoneNumberValue} isLink isPhone>
                <Styled.RequestCallContainer>
                  <Styled.RequestButton onClick={handleRequestCallClick} isSpaPage={isSpaPage}>
                    {requestCallTextTitle}
                  </Styled.RequestButton>
                </Styled.RequestCallContainer>
              </TextLabel>
              {!isSpaPage ? (
                <>
                  <TextLabel label={hotelAddressLabel} text={addressText} />
                  <TextLabel label={reservationDepartmentLabel} text={reservingMailText} isLink />
                  <TextLabel label={corporationClientsLabel} text={corporateClientsMailText} isLink />
                  <TextLabel label={cooperationLabel} text={cooperationMailText} isLink />
                  {contactsHRLabel && contactsHREmail && (
                    <TextLabel label={contactsHRLabel} text={contactsHREmail} isLink />
                  )}
                </>
              ) : (
                <>
                  <TextLabel label={contactEmailSpa} text={callMeMailSpa} isLink blue />
                  <TextLabel label={hotelAddressLabelSpa} text={addressTextSpa} />
                  <TextLabel label={spaWorkingHoursTitle} text={spaWorkingHoursValue} dangHTML />
                </>
              )}
              <Styled.ButtonTitle>{directionTitle}</Styled.ButtonTitle>
              <Styled.Buttons>
                {isSpaPage ? (
                  <Button title={contactUndegroundParkingSpa} blue onClick={handleParkingBtnClick} />
                ) : (
                  <Button title={parkingText} primary onClick={handleParkingBtnClick} />
                )}
                {isSpaPage ? (
                  <Button title={contactMainEntrainceSpa} blueSecondary onClick={handleEntranceBtnClick} />
                ) : (
                  <Button title={mainEntranceText} secondary onClick={handleEntranceBtnClick} />
                )}
              </Styled.Buttons>
            </Styled.Contacts>
            <YMap height={YMapHeight} zoom={YMapZoom} center={YMapCenter} icon={placemark} isSpaPage={isSpaPage} />
            {isSpaPage && (
              <Styled.SpaIconContainer>
                <OleaIcon />
              </Styled.SpaIconContainer>
            )}
          </Styled.ContactsWrapper>
        )}
      </Styled.Container>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={handleClosePopupClick}
        style={popupStyles}>
        <RequestCallForm handleClosePopup={handleClosePopupClick} />
      </Modal>
    </>
  );
};
